/* Header styles */

/* Navbar brand */
.navbar-brand img {
    margin-top: 10px; /* Sesuaikan margin atas logo */
  }
  
/* Custom nav link styles */
.navbar .nav-link.custom-nav-link {
  color: #545454; /* Default color */
  font-weight: bold;
  transition: color 0.3s; /* Transition only on text color */
  font-size: 17px;
  
}

.navbar .nav-link.custom-nav-link:hover,
.navbar .nav-link.custom-nav-link:active,
.navbar .nav-link.custom-nav-link.active {
  color: #25B7D3; /* Hover and active color */
}

/* Adjust navbar content alignment */
.navbar-collapse {
  justify-content: center; /* Menengahkan konten navbar */
  margin-left: -15%;
}


.navbar.fixed-top {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: #ffffff; /* Ensure background color to make it visible */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for visual separation */
}
  /* Active link styles */
  .custom-nav-link.active {
    font-weight: bold;
  }
  
  /* Reminders icon styles */
  .custom-nav-link.reminder-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .custom-nav-link.reminder-icon img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }
  
  /* Partisipan profile link styles */
  .nav-link.partisipan-profile {
    color: #A3A3A3;
    font-weight: bold;
    display: flex;
    align-items: center;
  }
  
  .nav-link.partisipan-profile img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
  
  /* Login/logout link styles */
  .custom-nav-link.login-logout {
    font-size: 16px;
  }
  