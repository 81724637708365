.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited, .bg-primary {
  /* background-color: #C8AD7F !important; */
  background-color:   #005F75 !important;
  
}

.text-primary{
  color :#C4EAF4 !important;
  
}


.btn{
  outline: none;
}

.checked {
  color: orange;
}

.card-img-top {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.before-content {
  margin-top: 40px;
}

.Signupclass-text {
  border: none;
  outline: none;
}


.dropdowns {
  position: relative;
}
/* bg-dark text-light */
.titles {
  background-color: #C4EAF4;
}

.whites {
  background-color: white;
}

.tfonts {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size:50px !important;
}

.tfonts-2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  font-size:35px !important;
}

.aboutfont {
  font-size:21px;
  background: #C4EAF4;

}


.back{
  background-color: #C4EAF4 !important;
}

.bgg {
  background: #C4EAF4 !important; 
  /* background-color: black; */
}

.choco {
  background-color: #C4EAF4 !important;
}



.size{
  font-size:larger;
}

#map {
	position:absolute;
	bottom: 0%;
	top: 100%;
	width: 10%;
	height: 4px !important;
 
	
}

.aboutimg {
  width: 100%;
}



.signup-img {
  /* background: url('map-unsplash.jpg'); */
  height: 18px;
  background-position: bottom;
   
}



.foot {
  color:coral;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #C4EAF4;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Header.css */
.custom-navbar {
  background-color: #C4EAF4;
  position: relative;
  padding: 15px 0;
}

.logo {
  max-width: 120px;
}

.nav-link {
  color: #141313;
  padding: 10px 15px;
  margin-right: 15px;
  transition: color 0.3s ease, background-color 0.3s ease;
  border-radius: 5px;
}

/* .nav-link:hover {
  color: #FFFFFF;
  background-color: #239BB2;
}

.nav-link.active {
  background-color: #84c4d4;
} */

.login-btn {
  background-color: #239BB2;
  color: #FFFFFF;
  border: none;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.login-btn:hover {
  background-color: #005F75;
}

