/* .section__padding {
    padding: 4rem 4rem;
} */

/* .footer {
    background-color: #2d2d32;
} */

.sb__footer {
    display: flex;
    flex-direction: column;
}

.sb__footer-links {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    text-align: left;
    margin-bottom: 2rem;
}

.sb__footer-links_div {
    width: 150px;
    margin: 1rem;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
}

.sb__footer-links_div h4 {
    color: white;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 0.9rem;
}

.sb__footer-links_div p {
    font-size: 12px;
    line-height: 15px;
    margin: 0.5rem 0;
    cursor: pointer;
    color: white;
}

.sb__footer-link-div p {
    font-size: 12px;
    line-height: 25px;
    margin: 0.5rem 0;
    cursor: pointer;
    color: white;
}

.socialmedia {
    display: flex;
    flex-direction: row;
}

.socialmedia img {
    width: 80%;
}

.sb__footer-below {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 0.2rem;
}

.sb__footer-below-links {
    display: flex;
    flex-direction: row;
}

.sb_footer-below-links p {
    font-size: 13px;
    line-height: 10px;
    margin-left: 2rem;
    color: white;
    font-weight: 600;
    flex-direction: row;
}

hr {
    color: white !important;
    width: 100%;
}

.sb__footer-copyright p {
    font-size: 13px;
    line-height: 15px;
    color: rgb(255, 255, 255);
    font-weight: 600;
}

@media screen and (max-width: 850px) {
    .sb__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .sb__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }
}

.sb__footer-links div {
    margin: 1rem 0;
}

.sb__footer-btn p {
    font-size: 14px;
    line-height: 20px;
}

.sb_footer-below {
    flex-direction: row;
    justify-content: left;
}

.sb_footer-below-links {
    flex-direction: column;
   
}

.sb_footer-below-links p {
    margin-left: 0rem;
    margin-top: 1rem;
}


@media screen and (max-width: 400px) {
    .sb__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}

/* Tulisan warna putih */
.footer,
.sb__footer-links_div h4,
.sb__footer-link-div p,
.sb__footer-links_div p,
.sb__footer-below-links p,
.sb_footer-below-links p,
.sb__footer-copyright p {

    color: white;
}
