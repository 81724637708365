/* AdminLayout.css */
.sidebar {
    background-color: #C4EAF4;
    color: black;
    height: 100%;
    border-right: 1px solid #C4EAF4;
  }
  
  .sidebar-link {
    color: #005E74;
    text-decoration: none;
    padding: 10px;
    display: block;
    border-radius: 8px; /* Menambahkan background rounded */
    transition: background-color 0.3s ease; /* Menambahkan efek transisi */
  }
  
  .sidebar-link:hover {
    color: white;
    background-color: #C4EAF4; /* Memberikan opacity saat hover */
  }
  
  .sidebar-link.active {
    color: white;
    background-color: #239BB2; /* Memberikan opacity saat aktif */
  }
  
  /* AdminLayout.css */

.admin-layout-content {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.admin-sidebar {
  width: 250px;
  background-color: #f8f9fa;
  transition: all 0.3s;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.admin-main-content {
  flex: 1;
  margin-left: 250px;
  padding: 20px;
}

.sidebar-link {
  color: #333;
}

.sidebar-link.active {
  font-weight: bold;
}

.admin-profile-info {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.admin-profile-info img {
  margin-left: 10px;
}
