.card {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
}

.card-img-top {
  object-fit: cover;
  height: 350px; 
}

.card-body {
  height: 100%; 
}

.card-title {
  font-size: 16px;
}

.card-text {
  margin-bottom: 1rem;
}

.btn-primary {
  margin-top: auto; /* Mendorong tombol ke bagian bawah Card */
}

/* CustomButton.css */
.custom-button {
  display: inline-block;
  background-color: #25B7D3; /* Ubah kode warna sesuai dengan tosca cerah yang diinginkan */
  color: #ffffff;
  border: 2px solid #ffffff;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.custom-button:hover {
  background-color: #C4EAF4; /* Ubah kode warna sesuai dengan tosca yang diinginkan saat tombol dihover */
}

.card-title,
.card-text {
  text-decoration: none;
}

.card-hover {
  transition: transform 0.3s;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);

}

.card-hover:hover {
  transform: scale(1.05);
}

.card-hover:hover .card-title,
.card-hover:hover .card-text {
  text-decoration: none;
}

.card-image {
  object-fit: cover;
  height: 200px;
}

.card-hover-circle {
  border-radius: 15px;
  transition: transform 0.2s ease-in-out;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  overflow: hidden;
  position: relative;
}

.card-hover-circle:hover {
  transform: translateY(-10px);
}

.card-image-circle {
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eaeaea;
  width: 100%;
}

.card-title-circle {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  flex-grow: 1;
}

.card-text-circle {
  font-size: 0.9rem;
  color: #555;
  flex: 1;
}

.custom-button-circle {
  background-color: #25B7D3;
  border: none;
  color: white;
  font-weight: bold;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.custom-link-circle {
  text-decoration: none;
  display: flex;
  flex-direction: column;
}

.custom-link-circle img {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
}

.title-container-circle {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.section.before-content-detail {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: white;
  color: #141313;
  padding-top: 30px;
  margin-top: 130px;
  position: relative;
  overflow: hidden;
}

.section.before-content-detail::before {
  content: "";
  position: absolute;
  top: 37%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #E3F7FE;
  z-index: 1;
}

.section-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}

.title {
  /* font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  margin-bottom: 20px;
  margin-left: 15%;
  font-size: 2.5rem;
}

.subtitle-detail {
  font-size: 1.2rem;
  margin-bottom: 20px;
  margin-left: 15%;

}
.section-title-intervensi {
  font-size: 30px;
  font-weight: bold;
  color: white;
  margin-bottom: 20px;
}

.welcome-image {
  width: 60%;
  height: auto;
  margin-right: 1%;
  margin-bottom: 30px;
}

.small-img {
  width: 85px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

.button-group {
  margin-bottom: 20px;
}

.intervensi-options {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  width: 100%;
  max-width: 600px; /* Mengatur lebar maksimum opsi intervensi */
  border-radius: 500px;
  padding: 20px;
  margin-bottom: 30px;
  margin-left: 27%;
  box-shadow: 4px -4px 5px rgba(0, 0, 0, 0.1);

}

.intervensi-option {
  text-align: center;
  margin: 0 20px;
  
}

.separator {
  height: 50px;
  width: 1px;
  background-color: #C4C4C4;
}

.intervensi-text {
  font-size: 1rem;
  color: #6C757D;
}

.intervensi-button {
  font-size: 1.5rem;
  color: #343A40;
  text-decoration: none;
}

.intervensi-button:hover {
  color: #007bff;
}



.info-card {
  border: none; /* Hapus border */
  border-radius: 55px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Tambahkan shadow */
  justify-content: center;
  width: 60%;
  margin-left: 23%;
  margin-top: 11%;
  font-size: 20px;
}

.info-card-left {
  border: none; /* Hapus border */
  border-radius: 55px;
  overflow: hidden;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1); /* Tambahkan shadow */
  justify-content: center;
  width: 60%;
  margin-left: 11%;
  margin-top: 11%;
  font-size: 20px;
}

.info-card-body {
  display: flex;
  align-items: center;
  justify-content: center;

}

.info-text-container {
  width: 105%;
  padding: 2px;
  margin-left: -1%;
  border-radius: 30px;
  

}

.info-image-container {
  width: 100%;
  
}

.info-image {
  width: 100%;
  height: auto;
  margin-left: -150%;
  margin-top: 10%;

}

.info-image-left{
  width: 100%;
  height: auto;
  margin-left: 60%;
  margin-top: 100;

}

.info-text {
  color: #333;
  font-size: 18px;
  line-height: 24px;
}

/* Di dalam Intervensi.css atau file CSS yang relevan */
.modal {
  z-index: 1050; /* Pastikan ini lebih tinggi dari elemen lain di halaman */
}

.swiper-container {
  background-color: white;
}

.swiper-pagination-bullet-active {
  background-color: #000; /* Contoh warna hitam untuk peluru pagination aktif */
}

.swiper-button-next,
.swiper-button-prev {
  color: #000; /* Contoh warna hitam untuk tombol navigasi */
}

.fade-in {
  opacity: 1;
  transition: opacity 1s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 1s ease-out;
}

.custom-card-width {
  max-width: 1000px; /* Atur lebar maksimum card */
  margin: 0 auto; /* Agar card berada di tengah */
}

/* Container styling */
.section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}


.header {
  background-color: #E0EFFF;
  padding: 20px;
}

.header-item {
  text-align: center;
}

.last-published {
  font-size: 12px;
  color: gray;
}

.main {
  padding: 20px;
}

.mental-health-card, .self-assessments-card, .self-assessment-card, .dass-card {
  margin: 20px 0;
  padding: 20px;
  border: none;
}

.mental-health-card h2, .self-assessments-card h2, .self-assessment-card h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.mental-health-card h3, .self-assessments-card h3, .self-assessment-card h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.mental-health-card p, .self-assessments-card ul, .self-assessment-card p {
  font-size: 16px;
}

.dass-card {
  padding: 5px;
}

.dass-card h5 {
  font-size: 16px;
  margin-bottom: 10px;
}

.dass-card p {
  font-size: 16px;
}

.small-card {
  height: 200px;

}
.small-card-suicide {
  height: 255px;
  margin-top: -5px; /* Adjust this value to move the card closer to the card above */

}

.medium-card {
  height: 300px;
}

.large-card {
  height: 350px;
  margin-top: -100px; /* Adjust this value to move the card closer to the card above */
}

.dass-card:hover {
  transform: scale(1.05); /* Menambahkan sedikit efek zoom pada hover */
  transition: transform 0.3s ease; /* Animasi perubahan */
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); /* Efek bayangan */
}

#waveform {
  width: 100%;
  height: 40px; /* Ubah tinggi waveform sesuai kebutuhan Anda */
}

.card-voice {
  width: 300px; /* Ubah lebar card-voice sesuai kebutuhan Anda */
  height: 60px; /* Atur tinggi card-voice agar menyesuaikan konten di dalamnya */
  max-width: 100%; /* Memastikan card tidak melebihi lebar parent jika diperlukan */
  margin-top: 10px; /* Atur margin atas card sesuai keinginan */
  margin-bottom: 10px; /* Atur margin bawah card sesuai keinginan */
}


/* Style for the modern table */
.modern-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  color: #333;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modern-table th,
.modern-table td {
  padding: 12px 15px;
  text-align: left; /* Ensure left alignment for all cells */
}

.modern-table thead {
  background-color: #25B7D3;
  color: white;
}

.modern-table tbody tr {
  border-bottom: 1px solid #ddd;
}

.modern-table tbody tr:nth-of-type(even) {
  background-color: #f9f9f9;
}

.modern-table tbody tr:last-of-type {
  border-bottom: 2px solid #25B7D3;
}

.modern-table th {
  font-size: 16px;
  font-weight: bold;
}

.modern-table td ul {
  padding-left: 20px;
}

.modern-table td ul li {
  margin-bottom: 5px;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
/* In your Intervensi.css */
.custom-checkbox {
  transform: scale(1.5); /* Makes the checkbox larger */
  margin-right: 10px; /* Adds some space between checkbox and label */
}

.custom-label {
  font-size: 18px; /* Adjusts the font size for the labels */
  margin-bottom: 0; /* Ensures no extra space below the label */
}

/* Additional styling to enhance the checkbox */
.custom-checkbox input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #EBBCBC; /* Custom accent color for the checkbox */
}

/* Styling for when the checkbox is checked */
.custom-checkbox input[type="checkbox"]:checked {
  background-color: black;
  border-color: #EBBCBC;
}

/* Optional: Enhance the label appearance */
.custom-label {
  color: #fff;
  font-weight: bold;
  margin-left: 5px;
}

.psikolog-card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.psikolog-card-body {
  flex: 1;
}

.psikolog-card-img {
  width: 80%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  margin-left: 26px;
}

.psikolog-card-link {
  text-decoration: none;
}
